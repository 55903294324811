export const $themeColors = {};

export const $themeBreakpoints = {};

export const $themeConfig = {
    app: {
        domain: process.env.VUE_APP_DOMAIN,
        base_url: process.env.VUE_APP_DOMAIN +'api/',
        default_locale: process.env.VUE_APP_DEFAULT_LOCALE,
        languages: ['pl', 'en'],
    },

    layout: {
        isRTL: false,
        skin: 'light', // light, dark, bordered, semi-dark
        routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
        type: 'vertical', // vertical, horizontal
        contentWidth: 'full', // full, boxed
        menu: {
            hidden: false,
            isCollapsed: false,
        },
        navbar: {
            // ? For horizontal menu, navbar type will work for navMenu type
            type: 'floating', // static , sticky , floating, hidden
            backgroundColor: '', // BS color options [primary, success, etc]
        },
        footer: {
            type: 'static', // static, sticky, hidden
        },
        customizer: true,
        enableScrollToTop: true,
    },
}
