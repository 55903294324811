import ability from './ability'

export const canNavigate = to => to.matched.some(route => {
    if(!route.meta.resource) return true;
    if(route.meta.resource == 'Auth') {
        if(!localStorage.getItem('userData')){
            return false;
        }
        return true;
    }

    if(localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData')).role == 'admin') {
        return true;
    }

    return ability.can(route.meta.action || 'read', route.meta.resource);
})

export const _ = undefined
