import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import I18n from '@core/../libs/i18n'
import {$themeConfig} from '@themeConfig'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import pages from './routes/pages'


Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
    // beforeRouteEnter: function(to, from, next) {
    //     next(vm => {
    //       alert(1);
    //         vm.$previousRoute = from;
    //     })
    // },
  routes: [
    {
      path: '/', redirect: { name: 'dashboard' } },

    ...pages,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach((to, from, next) => {

    if (store.hasModule('app')) {
      if(from.name && to.name != from.name){
          store.state.app.previousRouter = from;
          sessionStorage.setItem('previousRouter', window.location.href );
      }
    }

    // Vue.nextTick(() => {
    //     document.title = I18n.t(to.meta.title) || localStorage.getItem('appName');
    // });

  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {

    if (!isLoggedIn){


        return next({ name: 'auth-login' })
    }

    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
});

// router.b((to, from, next) => {
//     next(vm => {
//         vm.prevRoute = from
//     })
// });

router.afterEach((r) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none'
  }

    document.title = I18n.t(r.meta.title) || localStorage.getItem('appName');

});

export default router
