import {$themeBreakpoints} from '@themeConfig'
// import {AGREEMENT_DYNAMIC_STORE_NAME} from './../../views/pages/agreement/moduleHelper'
import {CONTACT_PREFIX} from './../../views/pages/contact/moduleHelper'

import {localize} from 'vee-validate'
import I18n from '@core/../libs/i18n'
import axios from '@axios'

export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        shallShowOverlay: false,

        editedDeal: false,
        editedWorkingTime: false,
        wkTimeModalOptions: false,

        translations: {},
        currentLocale: false,
        previousRouter: false,
        favicon: '',
        app_logo: '',
        app_logo_mini: '',
        app_name: '',
        viewableFile: '',
        general_settings: {},
    },
    getters: {
        currentBreakPoint: state => {
            const {windowWidth} = state
            if (windowWidth >= $themeBreakpoints.xl) return 'xl'
            if (windowWidth >= $themeBreakpoints.lg) return 'lg'
            if (windowWidth >= $themeBreakpoints.md) return 'md'
            if (windowWidth >= $themeBreakpoints.sm) return 'sm'
            return 'xs'
        },

    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
        },
        setEditedDeal(state, val) {
            state.editedDeal = val;
        },
        setEditedWorkingTime(state, val) {
            state.editedWorkingTime = val;
        },
        changeLocale(state, {locale, translations, rootState}) {
            state.translations[locale] = translations;
            I18n.setLocaleMessage(locale, translations);
            I18n.locale = locale;
            rootState.appConfig.locale = locale;
            state.currentLocale = locale;
            localize(locale);
            this._vm.$moment.locale(locale);

        }
    },
    actions: {
        addContact(ctx, data) {
            let vm = data.vm ? data.vm.$root : this._vm;
            return new Promise((resolve, reject) => {
                vm.async('post', '/' + CONTACT_PREFIX, data.itemData, function (response) {

                    // if (ctx.rootState[AGREEMENT_DYNAMIC_STORE_NAME]) {
                    //     ctx.rootState[AGREEMENT_DYNAMIC_STORE_NAME].contacts.push(response.data.item)
                    // }

                    return resolve(response);
                }, function (error) {
                    return reject(error)
                });
            })
        },
        editContact(ctx, data) {
            let vm = data.vm ? data.vm.$root : this._vm;
            return new Promise((resolve, reject) => {
                vm.async('put', '/' + CONTACT_PREFIX + '/' + data.itemData.id, data.itemData, function (response) {
                    return resolve(response);
                }, function (error) {
                    return reject(error)
                });
            })
        },
        deleteContact(ctx, id) {
            const self = this
            return new Promise((resolve, reject) => {
                self.async('delete', '/' + CONTACT_PREFIX + '/' + id, {}, function (resp) {
                    return resolve(resp);
                }, function (error) {
                    return reject(error)
                });
            })
        },

        UPDATE_LOCALE({dispatch, state, commit, rootState}, data) {
            return new Promise((resolve, reject) => {
                if (data.force || !state.translations[data.locale]) {
                    axios
                        .get('/language/trans', {params: {locale: data.locale}})
                        .then(function (response) {
                            commit('changeLocale', {
                                locale: data.locale,
                                translations: response.data,
                                rootState: rootState
                            });
                            return resolve(response);
                        })
                        .catch(error => reject(error));
                } else {
                    commit('changeLocale', {
                        locale: data.locale,
                        translations: state.translations[data.locale],
                        rootState: rootState
                    });
                    resolve(state.translations[data.locale]);
                }
            })

        },
        SET_CONFIG({dispatch, state, commit, rootState}, locale) {
            return new Promise((resolve, reject) => {
                    axios
                        .get('/general_settings', {})
                        .then(function (response) {
                            state.general_settings = response.data;
                            state.favicon = response.data.favicon;
                            state.app_logo = response.data.logo;
                            state.app_logo_mini = response.data.logo_mini;
                            state.app_name = response.data.SiteTitle;
                            localStorage.setItem('app_name', response.data.SiteTitle);
                            localStorage.setItem('app_logo', response.data.logo);
                            localStorage.setItem('app_logo_mini', response.data.logo_mini);
                            return resolve(response);
                        })
                        .catch(error => reject(error))

            })

        },

    }
}
