import Vue from 'vue'
import {
 ValidationProvider, ValidationObserver, configure, localize, extend
} from 'vee-validate'
// import en from 'vee-validate/dist/locale/en.json';
import en from './i18n/en.json';
import pl from './i18n/pl.json'

configure({
    generateMessage: localize({
        en,
        pl,
    })
});

localize('pl');


// register globalRules !!!

import {
    required,
} from 'vee-validate/dist/rules'
import {
    numeric,
} from './rules/validations'

extend('required', required);

// extend('myRule2', {
//     params: ['target'],
//     validate(value, { target }) {
//         return value === target;
//     },
//     message: 'Password confirmation does not match'
// });

// extend('myRule2', {
//     validate(value) {
//         return value.length >= 1000;
//     },
//     message: 'It is my global rule'
// });

Vue.component([ValidationProvider, ValidationObserver])
