window.Vue = require('vue').default;

import {ToastPlugin, ModalPlugin} from 'bootstrap-vue'

import VueCompositionAPI from '@vue/composition-api'
import {$themeConfig} from './../themeConfig'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'

import useJwt from '@/auth/jwt/useJwt'

import App from './App.vue'
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/vee-validator/validator'

import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
Vue.use(VuePersianDatetimePicker, {
    name: 'custom-date-picker',
    props: {
        editable: false,
        clearable: true,
        inputClass: 'form-control',
        placeholder: 'Select',
        altFormat: 'YYYY-MM-DD HH:mm',
        color: '#7c70f9',
        autoSubmit: true,
        popover: "auto",
        displayFormat: "DD-MM-YYYY", //for display
        format: "DD/MM/YYYY", // //for server
        showNowBtn: false
    },

});

import moment from "moment";
Vue.prototype.$moment = moment;
import pl from 'moment/locale/pl';

import Mixins from './mixins/global-mixins.js';
Vue.mixin(Mixins);

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueCompositionAPI);

// Feather Icons
require('@core/assets/fonts/feather/iconfont.css')

// import styles
require('@core/scss/core.scss')
require('animate.css')
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// Object.defineProperty(Vue.prototype, "$bus", {
//     get: function () {
//         return this.$root.bus;
//     }
// });

Vue.prototype.$domain = $themeConfig.app.domain;
Vue.prototype.$base_url = $themeConfig.app.base_url;
Vue.prototype.$locale = $themeConfig.app.default_locale;


const vm = new Vue({
    router,
    store,
    i18n,
    // locale: 'pl'
    // bus: new Vue({}),
    mainTabIndex: '',
    prevRoute: '',
    percentsHttpRequestDone: false,
    render: h => h(App),
    methods: {

    },
    computed:{
        appName(){
            return this.$store.state.app.app_name? this.$store.state.app.app_name : localStorage.getItem('app_name');
        },
        appLogo(){
            let logo = this.$domain + 'uploads/logos/';
            // let curBreakPoint = this.$store.getters['app/currentBreakPoint'];
            if(this.$store.state.verticalMenu.isVerticalMenuCollapsed){
                logo += (this.$store.state.app.app_logo_mini? this.$store.state.app.app_logo_mini : localStorage.getItem('app_logo_mini'));
            } else {
                logo += (this.$store.state.app.app_logo? this.$store.state.app.app_logo : localStorage.getItem('app_logo'));
            }
            return logo;
        }
    },

    created() {

        this.mainTabIndex = window.mainTabIndex = Date.now();
        localStorage.setItem('t-main', this.mainTabIndex);

        document.getElementById('custom--styles').setAttribute('href', $themeConfig.app.domain + 'styles');

        localStorage.setItem('lastActivity', Math.floor((new Date()).getTime() / 1000)); // in utc

        let authUserFromLocalStorage = this.authUser();

        window.addEventListener('load', function () {

            let sessionUserData = sessionStorage.getItem('userData');

            if (!sessionUserData && authUserFromLocalStorage) { //runs only if copy link and past directly to the same browser in new tab

                useJwt.refreshTokenAndProcess(function(resp){
                    if (authUserFromLocalStorage && resp.data && resp.data.userData) { //saving to tab sessionStorage
                        sessionStorage.setItem('userData', JSON.stringify(resp.data.userData));
                    } else {
                        useJwt.clearStorageAndRedirect(false);
                    }
                });

            } else if (sessionUserData && authUserFromLocalStorage) {
                useJwt.setRefreshUpdatingInterval();
            } else {
            }

        });

        if(authUserFromLocalStorage) {
            switch (authUserFromLocalStorage.language) {
                case '2':
                    this.$locale = 'pl';
                    break;
                default:
                    this.$locale = 'en';
            }
        }

        this.$store.dispatch('app/UPDATE_LOCALE', {locale: this.$locale}).then(()=>{
            if(authUserFromLocalStorage) {
                let curRouterTitle = this.$router.currentRoute.meta.title;
                if(curRouterTitle){
                    document.title = this.$t(curRouterTitle) || localStorage.getItem('app_name');
                }
            }
        });

        this.$store.dispatch('app/SET_CONFIG').then(()=>{
            let favicon = this.$store.state.app.favicon;
            if(favicon) {
                document.getElementById('favicon-icon').href = $themeConfig.app.domain + '/uploads/favicons/' + favicon;
            }

        });

        document.body.addEventListener('click',function(e){
            let lastActivityTime = Math.floor((new Date()).getTime() / 1000);
            window['lastActivity'] = lastActivityTime;
            localStorage.setItem('lastActivity', lastActivityTime);
        },true);

        document.addEventListener('input',function(e){
            if(e.target.tagName.toLowerCase() === 'input' && e.target.classList.contains('price-input')){
                let number = e.target.value;
                e.target.value = number.formatPrice(true);
            }
        },true);

    },
});

if(process.env.VUE_APP_ENVIRONMENT === 'prod') {
    Vue.config.productionTip = false
}

vm.$mount('#app');

require('./utils/global-utils');

window.vm = vm;

