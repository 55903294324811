import Ripple from 'vue-ripple-directive'
import { createPopper } from '@popperjs/core';

export default {

    data() {
        return {
            counterItvalId: false,
            percentsHttpRequestDone: false
        }
    },
    methods: {
        consoleLog(...data) {
                console.log(...data);
        },

        authUser(){
            return  JSON.parse(localStorage.getItem('userData'));
        },
        updateAuthUser(newData){
            let userData = this.authUser();
            for (let prop in newData) {
                if (userData.hasOwnProperty(prop)) {
                    userData[prop] = newData[prop];
                }
            }
            localStorage.setItem('userData', JSON.stringify(userData));
        },
        showToast(variant = 'success', text = '', title = false){

            const h = this.$createElement;

            const vNodesMsg = h(
                'p',
                { class: ['text-left', 'mb-0'] },
                [
                    text,
                    // ' Flashy ',
                    // h('strong', 'toast'),
                    // ` message #${this.count} `,
                ]
            );
            // Create the title
            let icon  = 'CheckSquareIcon';
            let defaultTitle  = this.$t('label_success');
            if(variant == 'error' || variant == 'warning'){
                icon = 'AlertCircleIcon';
                defaultTitle  = this.$t('label_error');
            }
            if(variant == 'info'){
                icon = 'InfoIcon';
                defaultTitle  = this.$t('label_informations');
            }
            if(variant == 'error') variant = 'danger';
            const vNodesTitle = h(
                'div',
                { class: ['d-flex', 'flex-grow-1', 'mr-2', 'align-items-center'] },
                [
                    h('feather-icon', { class: 'mr-1',  props: {icon: icon, size: '16'} }),
                    h('strong', { class: 'mr-2' }, title? title : defaultTitle),
                ]
            )
            // Pass the VNodes as an array for message and title
            let toastData = {
                solid: true,
                variant: variant,
                autoHideDelay: 4000,
                title:  [vNodesTitle],
                transition: "Vue-Toastification__bounce",
            };

            this.$bvToast.toast([vNodesMsg], toastData)
        },

        async: function (method, url, data, successCallback, errorCallback, options, hideToast = false) {

            if(options && options.showProgress){
                delete options.showProgress;
                options.onUploadProgress = progressEvent => {
                    this.percentsHttpRequestDone = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    if(this.percentsHttpRequestDone == 100){
                        this.percentsHttpRequestDone = false;
                    }

                }
            }
            this.$http[method](url, data, options).then(function (response) {

                if (typeof successCallback === 'function') {
                    successCallback.apply(this, [response]);
                }

                if (response.error || (response.data && response.data.error)) {
                    this.showToast('error', ((response.error) ? response.error : response.data.error));
                } else if (response.message || (response.data && response.data.message) && !hideToast) {
                    this.showToast('success',((response.message) ? response.message : response.data.message));
                } else if (response.warning || (response.data && response.data.warning)) {
                    this.showToast('warning',((response.warning) ? response.warning : response.data.warning));
                }

                if (response.redirect || (response.data && response.data.redirect)) {
                    this.redirect((response.redirect) ? response.redirect : response.data.redirect);
                }

            }.bind(this)).catch(function (error) {

                if (error) {

                    if (typeof errorCallback === 'function') {
                        errorCallback.apply(this, [error]);
                    }

                    if (!error.response) {
                        if(process.env.VUE_APP_ENVIRONMENT === 'dev') {
                            if(typeof error == 'string') {
                                this.showToast('error',error);
                            } else if(typeof  error == 'number') {
                                this.showToast('error',String(error));
                            } else {
                                console.error('response error: ', error);
                            }
                        }
                        return;
                    } else {
                        if (error.response.status === 422) {

                            let errorData = error.response.data.errors;
                            let message = '';
                            if(errorData){

                                for (let key in errorData) {

                                    message +=   errorData[key] + '\n';

                                }

                            } else {
                                message = error.response.data.error;
                            }

                            this.showToast('error',message);
                        } else if(error.response.data.error){
                            this.showToast('error',error.response.data.error);
                        }
                    }

                }

            }.bind(this));

        },

        focusOnFormError: function (is_invalid) {
        if(is_invalid) {
            // this.$nextTick(() => {
            setTimeout(function(){
                let elWithError = document.querySelector('form input.is-invalid');
                if(!elWithError){
                    elWithError = document.querySelector('form textarea.is-invalid');
                }
                if(!elWithError){
                    elWithError = document.querySelector('form .is-invalid input');
                }
                if(elWithError){
                    elWithError.focus();
                }

            }, 300);


        }

        },

        redirect(url) {
            window.location.replace(url);
        },
        reload() {
            window.location.reload();
        },
        logout(){
            this.confirmDialog(this.$t('auth.do_you_want_log_out'), '', function(self){
                self.redirect('/logout')
            }, false);
        },
        getValidationState({ dirty, validated, required: fieldRequired, changed, valid = null, errors }){
            const result = dirty || validated ? valid : null
            return !fieldRequired && !changed ? (errors?.length ? result : null) : result
        },
        userRole(user){

            let role = '';

            let userType = typeof user == 'object' ? parseInt(user.userType) : parseInt(user);

            switch(userType) {
                case 1: role = 'admin'; break;
                case 2: role = 'agent'; break;
                case 3: role = 'solicitor'; break;
                case 4: role = 'client'; break;
                case 5: role = 'consultant'; break;
                case 6: role = 'accountant'; break;
                case 7: role = 'secretary'; break;
            }

            if(role == 'admin' && user.is_superadmin == 1) {
                role = 'superadmin';
            }
            return role;
        },
        getRole(role, byRoleName = true) {
            const roles = {
                admin: 1,
                agent: 2,
                solicitor: 3,
                client: 4,
                consultant: 5,
                accountant: 6,
                secretary: 7,
            }
            if (byRoleName) return roles[role]

            return Object.keys(roles).find(key => roles[key] === role)
        },
        getSystemRoles(userPermission = null) {
            if (!userPermission) return []

            let roles = [
                {
                    role: 'agent',
                    disabled: false,
                    default: false
                },
                {
                    role: 'solicitor',
                    disabled: ['solicitor'].includes(userPermission),
                    default: ['solicitor', 'accountant'].includes(userPermission)
                },
                {
                    role: 'consultant',
                    disabled: ['solicitor', 'consultant'].includes(userPermission),
                    default: ['solicitor', 'consultant', 'accountant', 'admin', 'superadmin'].includes(userPermission)
                },
                {
                    role: 'accountant',
                    disabled: ['consultant', 'accountant'].includes(userPermission),
                    default: ['solicitor', 'consultant', 'accountant'].includes(userPermission)
                },
                {
                    role: 'secretary',
                    disabled: false,
                    default: false
                },
            ]


            return roles
        },
        rand(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        withPopper (dropdownList, component, {width}) {

            dropdownList.style.width = width;


            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: 'bottom',
                modifiers: [
                    {
                        name: 'offset', options: {
                            offset: [0, -1]
                        }
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn ({state}) {
                            component.$el.classList.toggle('drop-up', state.placement === 'top')
                        },
                    }]
            });


            return () => popper.destroy();
        },

        formatDate(val, toFormat, fromFormat) {
            return window.formatDate(val, toFormat, fromFormat)
        },
        formatSeconds(val, toFormat) {

            return window.formatSeconds(val, toFormat)
        },
        confirmAction(title, text, icon, successCallback, errorCallback, confirmButtonText){
            let self = this;
            this.$swal({
                title: title,
                text: text,
                icon: icon,
                showCancelButton: true,
                confirmButtonText: confirmButtonText? confirmButtonText : self.$t('label_yes'),
                cancelButtonText: self.$t('label_cancel'),
                showClass: {
                    popup: 'animate__animated animate__flipInX',
                },
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {

                    if (typeof successCallback === 'function') {
                        successCallback.apply(this, [result]);
                    }
                    // this.$swal({
                    //     icon: 'success',
                    //     title: self.$t('message_delete_success')+'!',
                    //     text: self.$t('label_target_deleted_successfully'),
                    //     customClass: {
                    //         confirmButton: 'btn btn-success',
                    //     },
                    // })
                } else if (result.dismiss === 'cancel') {
                    if (typeof errorCallback === 'function') {
                        errorCallback.apply(this, [result]);
                    }
                    // this.$swal({
                    //     title: self.$t('label_no_cancelled')+ '!',
                    //     text: self.$t('label_data_safe')+' :)',
                    //     icon: 'error',
                    //     customClass: {
                    //         confirmButton: 'btn btn-success',
                    //     },
                    // })
                }
            })
        },
        confirmDeleting(successCallback, errorCallback) {
          this.confirmAction(this.$t('confirm_delete'), this.$t('label_no_restore')+'!', 'warning', successCallback, errorCallback);
        },
        directHttp(method, url, file_name){

            this.$http.get(url,
                {
                    // responseType: 'arraybuffer',
                    // responseType: 'blob',
                    // headers:
                    //     {
                    //         'Content-Disposition': "attachment; filename=template.xlsx",
                    //         'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    //     },
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    // console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file_name); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => console.log(error));
        },

        previewFile(e, download_base_url, item){
            e.preventDefault();
            // let link = e.target.closest('.preview-firstly');
            item.download_base_url = download_base_url;
            this.$bvModal.show('preview-file-modal');
            this.$store.state.app.viewableFile = item;
        },

        config(val){
            return (val && this.$store.state.app.general_settings) ? this.$store.state.app.general_settings[val] : '';
        },

        htmlEncode(s) {
            return window.htmlEncode(s);
        }


    },
    directives: {//v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        Ripple,

        // 'b-tooltip':VBTooltip
    },
    filters:{
        nlToBr(str, is_xhtml) {
            if (typeof str === 'undefined' || str === null) {
                return '';
            }
            let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
        }
    },


}
