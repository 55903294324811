import Vue from 'vue'
import axios from 'axios'
import {$themeConfig} from '../../themeConfig'
// axios
// import https from 'https'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: $themeConfig.app.base_url,
  // timeout: 1000,
  headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, HEAD, OPTIONS",
      // 'Accept': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      // "Access-Control-Allow-Credentials": true
      },
    validateStatus: (status) => { return ((status >= 200 && status < 300) );}, //range errors will process in success block
});

Vue.prototype.$http = axiosIns;

export default axiosIns
