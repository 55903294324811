import axios from '@axios'
import JwtService from './jwtService'
import {getFingerprint} from './jwtService'

const useJwt = function useJwt(axiosIns, jwtOverrideConfig) {
    return new JwtService(axiosIns, jwtOverrideConfig);
};

const jwt = useJwt(axios, {});

export {getFingerprint};
export default jwt
